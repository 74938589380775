@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap");
@import url("~tldraw/tldraw.css");
@font-face {
  font-family: BopomofoRuby;
  src: url(./font/BopomofoRuby1909-v1-Regular.ttf); }

*,
*:after,
*:before {
  box-sizing: border-box; }

html,
body {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Arial, Microsoft JhengHei, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif,  Helvetica, sans-serif, "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

button {
  color: #000;
  width: 47px;
  height: 47px;
  outline: none; }

.react-confirm-alert-overlay {
  z-index: 1301; }

.react-confirm-alert-body {
  font-size: 20px;
  width: 600px;
  padding: 32px;
  color: #121232; }

.react-confirm-alert-body > h1 {
  font-size: 24px; }

.react-confirm-alert-button-group {
  justify-content: flex-end;
  margin-top: 124px; }

.react-confirm-alert-button-group > button {
  width: max-content;
  height: auto;
  font-size: 16px;
  padding: 6px 64px;
  line-height: 24px;
  order: 1; }
  .react-confirm-alert-button-group > button:nth-child(2) {
    order: -1;
    color: #121232;
    background-color: #fff;
    transition: 0.35s; }
    .react-confirm-alert-button-group > button:nth-child(2):hover {
      background-color: #eee; }

#root {
  width: 100%;
  height: 100%;
  overflow: auto; }

.tl-background {
  background: transparent !important; }

.tl-container {
  font-size: inherit !important; }

[data-color] {
  stroke: var(--color); }

body > iframe {
  user-select: none; }
